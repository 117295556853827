import axios from 'axios';
import axiosRetry from 'axios-retry';

import store from '@/store';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_MEDIA_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 1 });

client.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/getToken'];
    if (token) config.headers['X-Removify-Token'] = token;

    return config;
  },
);

export default {
  /**
   * Ping
   */
  ping() {
    return client.get('/ping');
  },

  /**
   * Image - uploadImage
   * @param {file} image
   */
  uploadImage(image) {
    return client.post('/upload/image', image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * Image - uploadImage
   * @param {string} file_id
   */
  getImage(file_id) {
    return client.get(`/upload/image/${file_id}`);
  },

  /**
   * Image - removeImage
   * @param {string} file_id
   */
  removeImage(file_id) {
    return client.delete(`/upload/${file_id}`);
  },
};
