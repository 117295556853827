import { getCustomer } from '@/api';
import type { AcceptedCurrency } from '@/types/currency';
import { customerSchema } from '@/types/customer';
import type { Customer } from '@/types/customer';
import type { AuBecsDebit, CardWithExpire } from '@/types/payment';
import { formatMoney, zod } from '@/utils';
import { isCreditCardExpired, isCreditCardExpireSoon } from '@/utils/stripe';

type PaymentMethod = CardWithExpire | AuBecsDebit;

export const useCustomerStore = defineStore('customer', () => {
  const customer = ref<Customer | null>(null);
  const loading = ref(true);

  const paymentMethods = computed(() => {
    const _customer = customer.value;
    if (!_customer?.payment_methods) return [];

    const _paymentMethods: PaymentMethod[] = [];

    for (const p of _customer.payment_methods) {
      if (p.type === 'card') {
        _paymentMethods.push({
          ...p,
          expired: isCreditCardExpired(p.exp_month, p.exp_year),
          expireSoon: isCreditCardExpireSoon(p.exp_month, p.exp_year),
        });
      } else if (p.type === 'au_becs_debit') {
        _paymentMethods.push(p);
      }
    }

    // sort by is_default, so make sure the default is first
    _paymentMethods.sort((a, b) => {
      if (a.is_default) return -1;
      if (b.is_default) return 1;
      return 0;
    });

    return _paymentMethods;
  });
  const defaultPaymentMethod = computed(() => {
    return paymentMethods.value.find(i => i.is_default);
  });
  const defaultPaymentMethodId = computed(() => {
    if (!defaultPaymentMethod.value) {
      return null;
    }
    return defaultPaymentMethod.value.id;
  });
  const hasPaymentMethods = computed(() => paymentMethods.value.length > 0);
  const paymentMethodsCardOnly = computed(() => paymentMethods.value.filter(method => method.type === 'card'));
  const paymentMethodsDirectDebitOnly = computed(() =>
    paymentMethods.value.filter(method => method.type === 'au_becs_debit'));
  const currencyCode = computed<AcceptedCurrency | undefined>(() => customer.value?.currency_code ?? undefined);
  const currencySymbol = computed(() => currencyCode.value ? formatMoney(0, currencyCode.value).slice(0, 1) : '');
  const hasExpiredCard = computed(() => {
    return hasPaymentMethods.value === false
      ? false
      : paymentMethods.value.some((method) => {
        if (method.type !== 'card') return false;
        return method.expired;
      });
  });
  const hasCardExpireSoon = computed(() => {
    return hasPaymentMethods.value === false
      ? false
      : paymentMethods.value.some((method) => {
        if (method.type !== 'card') return false;
        return isCreditCardExpireSoon(method.exp_month, method.exp_year);
      });
  });
  const isDefaultPaymentExpired = computed(() => {
    const defaultPaymentMethod = paymentMethods.value.find(method => method.is_default);
    if (!defaultPaymentMethod) return false;
    if (defaultPaymentMethod.type !== 'card') return false;

    return defaultPaymentMethod.expired;
  });
  const isDefaultPaymentExpireSoon = computed(() => {
    const defaultPaymentMethod = paymentMethods.value.find(method => method.is_default);
    if (!defaultPaymentMethod) return false;
    if (defaultPaymentMethod.type !== 'card') return false;

    return isCreditCardExpireSoon(defaultPaymentMethod.exp_month, defaultPaymentMethod.exp_year);
  });
  const hasValidCreditCard = computed(() => {
    const cards = paymentMethodsCardOnly.value;
    if (!cards.length) return false;

    return cards.some((c) => {
      return !c.expired;
    });
  });
  const validPaymentMethods = computed(() => {
    const _paymentMethods: PaymentMethod[] = [];
    // If no payment methods, return false
    const paymentMethodsArray = paymentMethods.value;
    if (!paymentMethodsArray.length) return _paymentMethods;

    for (const p of paymentMethodsArray) {
      if (p.type === 'au_becs_debit') {
        _paymentMethods.push(p);
      } else if (p.type === 'card') {
        if (!p.expired) {
          _paymentMethods.push(p);
        }
      }
    }

    // Push default to top
    _paymentMethods.sort((a, b) => {
      if (a.is_default) return -1;
      if (b.is_default) return 1;
      return 0;
    });

    return _paymentMethods;
  });
  const hasValidPaymentMethod = computed(() => {
    return validPaymentMethods.value.length > 0;
  });
  const fetchCustomer = async (reload = false) => {
    // If customer already exist and not reload, just return
    if (!reload && customer.value) {
      return;
    }
    const organizationStore = useOrganizationStore();
    if (!organizationStore.organizationId) {
      return;
    }
    loading.value = true;
    const { data: responseData, status } = await getCustomer();

    loading.value = false;

    if (status !== 200 || !responseData) {
      // In response callback, the error already send to sentry
      // so just return here
      return;
    }

    // Just send to sentry if error
    const { data } = zod.verifyData(responseData.customer, customerSchema, true);
    if (data) {
      customer.value = data;
    }
  };

  return {
    /**
     * customer's data
     */
    customer,
    /**
     * Action to fetch customer data
     */
    fetchCustomer,
    /**
     * The default payment method
     */
    defaultPaymentMethod,
    /**
     * The id of default payment method
     */
    defaultPaymentMethodId,
    /**
     * Get payment methods from customer data
     */
    paymentMethods,
    /**
     * Indicate if the data is still loading
     */
    loading,
    /**
     * Does this customer have any payment methods
     */
    hasPaymentMethods,
    /**
     * Get only card payment methods
     */
    paymentMethodsCardOnly,
    /**
     * Get only direct debit payment methods
     */
    paymentMethodsDirectDebitOnly,
    /**
     * Get currency code from customer data
     */
    currencyCode,
    /**
     * The currency symbol, e.g. $ for aud
     */
    currencySymbol,
    /**
     * Has any card expire soon
     */
    hasCardExpireSoon,
    /**
     * Has any expired card
     */
    hasExpiredCard,
    /**
     * Is default payment method expired
     */
    isDefaultPaymentExpired,
    /**
     * Is default payment method expire soon
     */
    isDefaultPaymentExpireSoon,
    /**
     * Valid aka not expired payment methods, the direct debit is always valid
     */
    validPaymentMethods,
    /**
     * Whether the customer has valid credit cards
     */
    hasValidCreditCard,
    /**
     * Whether this customer has valid payment method
     */
    hasValidPaymentMethod,
  };
});
