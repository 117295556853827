import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_CRM_API_BASE_URL;

export const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 2 });

client.interceptors.request.use(
  (config) => {
    const { token } = storeToRefs(useUserStore());
    const { customerId } = storeToRefs(useOrganizationStore());

    if (token.value) config.headers['X-Removify-Token'] = token.value;
    if (customerId.value) config.headers['X-Removify-Customer-Id'] = customerId.value;
    return config;
  },
);
