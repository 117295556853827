import type { z, ZodError, ZodSchema } from 'zod';

import { addBreadcrumbForError } from '@/utils/error';

/**
 * @param data
 * @param schema
 * @example
 * return verifyData<typeof reportingSchema>(
 *  response.data.data,
 *  reportingSchema,
 * );
 *
 */
export const verifyData = <T extends ZodSchema>(
  data: z.infer<T>,
  schema: T,
  sendToSentry = false,
) => {
  try {
    const parsedData = schema.parse(data);
    return { error: null, data: parsedData as z.infer<T> };
  } catch (error) {
    console.error(error);
    addBreadcrumbForError(error, sendToSentry);
    return { error, data: null } as { error: ZodError | Error; data: null; };
  }
};
